<template>
  <div class="view pa24">
    <div class="d-flex" style="justify-content: space-between">
      <div style="">
        <el-button class="ml10" type="primary" @click="centerDialogVisible = true"
        >+ 新建履历</el-button
        >
        <el-button class="ml10" type="" @click="previewDialogVisible = true"
        >预览履历</el-button
        >
      </div>
      <!-- <div>
        <el-button class="ml10" type="" @click="wanshan"
        >完善履历</el-button
        >
      </div> -->
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
        style="width: 100%"
    >
      <template v-slot:table>
        <el-table-column prop="unit" align="center" show-overflow-tooltip label="工作单位" />
        <el-table-column prop="position" align="center" show-overflow-tooltip label="职位" />
        <el-table-column prop="startDate" align="center" label="开始时间" />
        <el-table-column prop="endDate" align="center" label="结束时间" />
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button class="mr10" type="text" @click="open(scope.row)">编辑</el-button>
            <customPopconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm='del(scope.row.resumeId)'
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button slot="reference" style="color: #fd634e" type="text" >删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="新建履历"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="workUnits" label="工作单位">
          <el-input
              clearable
              v-model="ruleForm.workUnits"
              style="width: 100%"
              placeholder="请输入工作单位"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="position" label="职位">
          <el-input
              clearable
              v-model="ruleForm.position"
              style="width: 100%"
              placeholder="请输入职位"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="startDate" label="开始时间">
          <el-date-picker
              clearable
              value-format="yyyy-MM-dd"
              style="width: 100%"
              v-model="ruleForm.startDate"
              type="date"
              :picker-options="pickerOptions"
              placeholder="开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="endDate" label="结束时间">
          <el-date-picker
              clearable
              value-format="yyyy-MM-dd"
              style="width: 100%"
              v-model="ruleForm.endDate"
              type="date"
              :picker-options="pickerOptions"
              placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工作内容" prop="details">
          <el-input type="textarea" v-model="ruleForm.details"  maxlength="300"  show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="预览"
        :visible.sync="previewDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <div style="margin-bottom: 30px;height: 500px;overflow-y: auto">
        <timeline :timeline-list="tableData"></timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {selectResumePageList,addResume,upResume,delResume,upMember} from "@/api/members";
import Timeline from "./Timeline";
export default {
  name: "CVList",
  components: {
    commonTable,
    customPopconfirm
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      centerDialogVisible: false,
      previewDialogVisible:false,
      tableData: [],
      ruleForm:{
        workUnits:'',
        position:'',
        startDate:'',
        endDate:'',
        details:''
      },
      rules:{
        workUnits: [{required: true, message: "请输入工作单位", trigger: "blur"}],
        position: [{required: true, message: "请输入职位", trigger: "blur"}],
        startDate: [{required: true, message: "请选择开始时间", trigger: "blur"}],
        endDate: [{required: true, message: "请选择结束时间", trigger: "blur"}],
        details: [{required: true, message: "请输入内容", trigger: "blur"}],
      },
      upDate:1,
      resumeId:'',
    };
  },
  created() {
    this.queryPage();
  },
  computed: {

  },
  methods: {
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        examineStatus:0,
        userId: this.$route.query.userId,
        excellentId: 0,
      };
      try {
        this.loading = true;
        const result = await selectResumePageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    determine(data) {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              userId: this.$route.query.userId,
              unit: this.ruleForm.workUnits,
              position:this.ruleForm.position,
              startDate:this.ruleForm.startDate,
              endDate:this.ruleForm.endDate,
              content:this.ruleForm.details,
              excellentId: 0,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addResume(data);
            }else {
              data.resumeId = this.resumeId
              await upResume(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      let data = JSON.parse(JSON.stringify(val));
      this.ruleForm.workUnits = data.unit;
      this.ruleForm.position = data.position;
      this.ruleForm.startDate = data.startDate;
      this.ruleForm.endDate = data.endDate;
      this.ruleForm.details = data.content;
      this.resumeId = data.resumeId
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val){
      try {
        await delResume({resumeId:val});
        await this.queryPage();
      } catch (error) {
        console.log(error)
      }
    },
    close(){
      (this.ruleForm = {
        workUnits:"",
        position:"",
        startDate:"",
        endDate:"",
        details:"",
      }),
          this.centerDialogVisible = false;
    },
    wanshan(){
      let data = {
        memberId: this.$route.query.memberId,
        ifResume:0
      }
      upMember(data)
          .then(() => {
            this.$message({
              message: "保存成功",
              type: "success",
              center: true,
            });
            this.$router.go(-1);
          })
          .catch(err => {
            this.$message({
              message: err.message,
              type: "success",
              center: true,
            });
          });
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-textarea__inner{
  height:300px;
}
</style>

